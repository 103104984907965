html {
  background-image: url("./background.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  height: 100%;
}

.hero {
  background-color: coral;
}

.card {
  height: 100%;
}

.card:hover {
  cursor: pointer;
}